import { defineStore } from 'pinia';
import type TransportRequestResource from '~/api/resource/TransportRequestResource';
import { useApiConsumer } from '~/composables/useApiConsumer';
import type { TravelingType } from '~/api/enum/TravelingType';

export const useTransportRequestStore = defineStore('transport_request', () => {
  const client = useApiConsumer();

  const transportRequests = ref<TransportRequestResource[]>([]);
  const currentItineraryTransportRequests = ref<TransportRequestResource[]>([]);

  async function populate() {
    transportRequests.value = await client.extractResultFromPaginatedResponse('/transport_requests');
  }

  async function populateTransportRequestWithoutItinerary(startDate: string, endDate: string) {
    transportRequests.value = await client.extractResultFromPaginatedResponse('/transport_requests/without_matching_itineraries', {
      params: {
        startDate,
        endDate
      }
    });
  }

  async function populateAndCheckTransportRequestDay(date: string, travelingType: TravelingType | null, itineraryId?: number) {
    transportRequests.value = await client.extractResultFromPaginatedResponse('/transport_requests', {
      params: {
        checkTransportRequestDay: true,
        currentDate: date,
        travelingType: travelingType ? [travelingType] : []
      }
    });

    if (itineraryId) {
      currentItineraryTransportRequests.value = await client.extractResultFromPaginatedResponse('/transport_requests', {
        params: {
          checkTransportRequestDay: true,
          currentDate: date,
          itineraryId: itineraryId
        }
      });
    }
  }

  function getMergedTransportRequest(): TransportRequestResource[] {
    const mergedRequests = [...transportRequests.value, ...currentItineraryTransportRequests.value];

    const uniqueRequestsMap = new Map<number, TransportRequestResource>();
    mergedRequests.forEach((request) => {
      uniqueRequestsMap.set(request.id, request);
    });

    return Array.from(uniqueRequestsMap.values());
  }

  function $reset() {
    transportRequests.value = [];
    currentItineraryTransportRequests.value = [];
  }

  return {
    transportRequests,
    populate,
    populateAndCheckTransportRequestDay,
    getMergedTransportRequest,
    currentItineraryTransportRequests,
    populateTransportRequestWithoutItinerary,
    $reset
  };
});
